let enumObject = {};


//告警等级
enumObject.eventLevel = {
    1:"普通事件", 
    2: "重要事件",
    3: "非常紧急"
    
};
//告警等级
enumObject.eventState = {
    "alarming": "未处理",
    "ignored": "忽略",
    "processed": "解决",
    "received": "已领取"
    
};


export default enumObject;