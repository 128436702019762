import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import indexEdit from "../../components/indexEdit";
import pendingEdit from "../../components/pendingEdit";
import enumObject from '../../utlis/enum.js';
import { getGroup, getEvent, postReceive } from '../../api/index.js';
import { clearInterval } from 'timers';
export default {
  name: 'all',
  data: function data() {
    return {
      productGroup: {},
      events: [],
      page: 1,
      enums: {},
      nextPage: true,
      loading: false,
      eventsTimer: null,
      groupId: -1
    };
  },

  /* eslint-disable */
  components: {
    "index-edit": indexEdit,
    "pending-edit": pendingEdit
  },
  created: function created() {
    this.getEvents();
    this.enums = enumObject;
  },
  methods: {
    getEvents: function getEvents() {
      var _this = this;

      //获取所有事件
      var limit = 9;
      this.loading = true;
      getEvent(limit, this.page).then(function (res) {
        if (res.data.code == 200) {
          _this.$nextTick(function () {
            _this.nextPage = res.data.next_page;
            var data = res.data.result;
            _this.events = data.filter(function (item) {
              return item;
            });
            _this.loading = false;
          });
        }
      }).catch(function (e) {
        _this.loading = false;
      });
    },
    handleEdit: function handleEdit(index, row) {
      this.$refs.indexEdit.editReceive(index, row);
    },
    handleDeal: function handleDeal(row) {
      this.$refs.pendingEdit.editReceive(row);
    },
    prev: function prev() {
      if (this.page > 1) {
        this.page -= 1;
        this.getEvents();
      }
    },
    next: function next() {
      if (this.nextPage) {
        this.page += 1;
        this.getEvents();
      }
    },
    groupShow: function groupShow(id) {
      this.groupId = id;
    },
    groupClose: function groupClose() {
      this.groupId = -1;
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.eventsTimer);
  }
};