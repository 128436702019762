//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getCheckcode, getAuthcode } from '../../api/index.js';
export default {
  name: 'V-code',
  data: function data() {
    return {
      input: '',
      groupIds: [],
      groups: [],
      loading: false,
      authDialog: false
    };
  },
  created: function created() {// console.log( this.$route.params)
  },
  methods: {
    handleClose: function handleClose() {
      this.groupIds = [];
      this.authDialog = false;
      this.loading = false;
    },
    authSure: function authSure() {
      var _this = this;

      getAuthcode(this.input, this.groupIds).then(function (res) {
        //获取产品组ID
        if (res.data.code === 200) {
          _this.loading = false;

          _this.$message({
            message: '分配权限并登录成功',
            type: 'success'
          });

          _this.authDialog = false;
          _this.loading = false;
        }
      });
    },
    authorize: function authorize() {
      var _this2 = this;

      this.loading = true;
      getCheckcode(this.input).then(function (res) {
        //获取产品组ID
        if (res.data.code === 200) {
          _this2.groups = res.data.result; // console.log(222,this.groups)

          _this2.authDialog = true;
        }
      });
    }
  }
};