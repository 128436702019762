<template>
	<el-header class='header' height='70px'>
		<div class=''>
			<el-dropdown>
				<span class="user-name">{{username?username:"未登录"}}</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item @click.native="logout">退出</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</el-header>
</template>

<script>
import {signOut} from '../api/index.js'
	export default {
		data() {
			return {
				username:localStorage.getItem("username")
			}
		},
		// computed: {
		// 	...mapState([
		// 		"userName","userCharacter"
		// 	])
		// },
		methods: {
			logout() { 
				/* eslint-disable */
				signOut().then(res => {
					if (res.data.code === 200) {
						var keys = document.cookie.match(/[^ =;]+(?=)/g);
						if (keys) {
							for (var i = keys.length; i--;)
								document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
						}
						localStorage.clear()
						this.$router.push('/login') 
					}
				})
			
			}
		},
		created() {
			
		}
	}
</script>

<style scoped="scoped">
.header{
	padding: 0 30px 0 0;
	box-shadow: 0px 4px 8px 0px rgba(63, 63, 63, 0.1);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: #FFFFFF;
}
.user-name{
	
}
</style>
