const axios = require('axios')

axios.defaults.baseURL='/';//测试
axios.defaults.baseURL='https://alarm.leqi.us/backstage';//线上
axios.defaults.withCredentials = true;
 
   
const getCode = () => axios.get('/public/user/authorization_code') //授权码 
const signOut = () => axios.get('/user/sign_out') //退出
const getCheckcode = (code) => axios.get(`user/authorization_code/${code}/check`)  
const getAuthcode = (authorize_code,read_group_ids) => axios.post(`user/authorization_code/authorize`, { 
	authorize_code,
	read_group_ids,
 
})  
const postEmail = (email) => axios.post(`public/user/register/email`, { 
	email
})  

const getToken = (token) => axios.get(`public/user/authorization_code/state?token=${token}`)   
const register = (email, name,password,code) => axios.post('public/user/register', { 
	email,
	name,
	password,
	code
})   
const login = (account_name, password) => axios.post('public/user/login', { 
	account_name,  
	password  
})   

/* 报警事件 */
const getGroup = () => axios.get(`/product_group`)//获取当前用户权限

const getEvent = (limit,page) => axios.get(`/alarm_event?limit=${limit}&page=${page}`)//  获取所有事件
const getPending = () => axios.get(`/alarm_event/pending`)//  获取所有事件
const postReceive = (event_id,estimated_time_to_resolve) => axios.post(`/alarm_event/${event_id}/receive`, {
	estimated_time_to_resolve
}) // 事件预计的解决时间
const postHandle = (event_id,processing_explanation,state) => axios.post(`/alarm_event/${event_id}/handle`, {
	processing_explanation,state
}) // 事件预计处理
/* 权限 */
const postAuthorize = (read_group_ids,write_group_ids) => axios.post(`user/authorize`, {
	read_group_ids,write_group_ids
})
/* 统计 */
const getTypes = () => axios.get(`/event_type`)//任务类型
const getCountdown = (page,limit,self,event_type) => axios.get(`/countdown_event?page=${page}&limit=${limit}&self=${self}&event_type=${event_type}`)//获取统计
const postTimingTask = (countdown_event_id, state) => axios.post(`/countdown_event/${countdown_event_id}`, { state })//修改任务

const createTask = (event_type, event_title, event_content, product_group_ids, alarm_nodes, crash_time) => axios.post(`/countdown_event`, {
	event_type, event_title, event_content, product_group_ids, alarm_nodes, crash_time
})//创建任务

export {
	axios,
	signOut,
	getCode,
	postEmail,
	getCheckcode,
	getAuthcode,
	getToken,
	getGroup,
	getEvent,
	register,
	login, 
	postReceive,
	getPending,
	postHandle,
	postAuthorize,
	getTypes,
	getCountdown,
	postTimingTask,
	createTask
}
