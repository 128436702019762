<template>
	<div>
	
    <el-dialog
      class="dialog"
      title="新建倒计时事件"
      :visible.sync="taskDialog"
      width="35%"
      center
      style="text-align: center;"
      :before-close="handleClose"
      >
      <el-form :rules="rules" ref="ruleForm"  :model="form" label-width="80px">
        <el-form-item label="事件类型"  prop="typesVal">
          <el-select v-model="form.typesVal" placeholder="请选择事件类型" >
            <el-option
              v-for="item in types"
              :key="item.desc"
              :label="item.desc"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件标题" label-width="80px" prop="text">
          <el-input   v-model="form.text" placeholder="请填写事件标题" ></el-input>
        </el-form-item>
        <el-form-item label="事件内容" label-width="80px" prop="textarea">
          <el-input type="textarea"  v-model="form.textarea" placeholder="请填写事件正文内容"></el-input>
        </el-form-item>
        <el-form-item label="产品组" label-width="80px" prop="group">
          <el-select
            v-model="form.group"
            multiple
            collapse-tags
            placeholder="请选择产品组">
            <el-option
              v-for="item in groups"
              :key="item.group_name"
              :label="item.group_name"
              :value="item.group_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间节点" label-width="80px" class="formTimer" prop="Time1">
          <el-input placeholder="第一次报警剩余小时"  v-model="form.Time1"></el-input>
          <el-input placeholder="第二次报警剩余小时"  v-model="form.Time2"></el-input>
          <el-input placeholder="第三次报警剩余小时"  v-model="form.Time3"></el-input>
        </el-form-item>
        <el-form-item label="结束时间" label-width="80px" >
          <div class="block">
            <el-date-picker
              v-model="form.endTime"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取 消</el-button>
        <el-button type="primary" @click="receiveSure">确 定</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
import {getGroup,postHandle,createTask} from '../../api/index.js'
export default {
    name: "pendingEdit",
    data() {
      return{
        groups:[],
        taskDialog: false,
        receiveRow:{},
        form: {
          typesVal: '',
          text: '',
          textarea: '',
          group: '',
          Time1: '',
          Time2: '',
          Time3: '',
          endTime: '',
        },
        rules: {
          typesVal: [
            { required: true, message: '请选择事件类型', trigger: 'change' }
          ],
          text: [
            { required: true, message: '请填写事件标题', trigger: 'blur' }
          ],
          textarea: [
            { required: true, message: '请填写事件正文内容', trigger: 'blur' }
          ],
          group: [
            { type: 'array', required: true, message: '请至少选择一个产品组', trigger: 'change' }
          ],
          Time1: [
            { required: true, message: '请填写三个报警倒计时', trigger: 'blur' }
          ],
          endTime: [
            { type: 'date', required: true, message: '请选择结束时间', trigger: 'change' }
          ],
         
        }
      }
    },
    props:["types"],
    created() {

    },
    mounted() {
      getGroup().then(res=>{
        if(res.status==200){
          this.groups=res.data.result
          }
        })
    },
    methods: {
      editReceive() {
        this.taskDialog = true
      }, 
 
      receiveSure() { 
        
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let nodes= [+this.form.Time1,+this.form.Time2,+this.form.Time3]
            let Time=this.$moment(this.form.endTime).format("YYYY-MM-DD HH:mm:ss")
            this.logining = true
            console.log(Time,this.form.endTime)
						createTask(this.form.typesVal,this.form.text,this.form.textarea,this.form.group,nodes,Time).then(res => {
								if (res.data.code === 200) {
                  this.$message({
                    message: '创建倒计时任务成功',
                    type: 'success'
                  })
                  this.taskDialog = false
                  this.form={}
                  this.$emit('getCountdown')
								}
							})
					} else {
						return false
					}
				})
      },
      handleClose() { 
        this.taskDialog = false
        this.form={}
      }
    }
  }
</script>

<style lang="less" scoped>
.el-select{ 
  width: 100%
}
.el-form-item__label{ 
  text-align: center
}
.formTimer /deep/ .el-form-item__content{ 
  display: flex;
  box-sizing: border-box;
  .el-input{ 
    flex: auto;
    margin-right: 3%;
    input{ 
      text-align: center
    }
  }
  .el-input:nth-last-child(1){ 
    margin-right: 0;
  }
}
.el-date-editor{ 
  width: 100%;
}
.el-date-editor  /deep/ .el-input__inner{ 
  text-align: center;
}
.el-date-editor  /deep/ .el-input__prefix{ 
  opacity: 0;
}

</style>