<template>
 <div >
   <el-main 
   >
    <audio v-show="false" v-if="music" controls  loop  autoplay >
      <source src="../../assets/14039.mp3"  type="audio/mp3">
    </audio>
    <el-table
      :data="events"
      style="width: 100%;">	
      <el-table-column label="事件ID"  width="70" prop="event_id" show-overflow-tooltip align="center">
        <template slot-scope="scope" >
          <span  :style="{color:enums.eventLevel[scope.row.level]=='非常紧急'?'red':enums.eventLevel[scope.row.level]=='重要事件'?'Orange':'#606266'}">{{scope.row.event_id}}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="isAndroid===-1" show-overflow-tooltip align="center" prop="level" label="告警等级" width="100">
        <template slot-scope="scope" >
          <span  :style="{color:enums.eventLevel[scope.row.level]=='非常紧急'?'red':enums.eventLevel[scope.row.level]=='重要事件'?'Orange':'#606266'}">{{enums.eventLevel[scope.row.level]}}</span>
        </template>
      </el-table-column>
      <el-table-column :style="{color:'red'}" label="告警事件"  width="150" prop="event_title" show-overflow-tooltip align="center">
        <template slot-scope="scope" > 
          <span :style="{color:enums.eventLevel[scope.row.level]=='非常紧急'?'red':enums.eventLevel[scope.row.level]=='重要事件'?'Orange':'#606266'}">{{scope.row.event_title}}</span>
        </template>
      </el-table-column>
      <el-table-column label="告警类型"  width="100" prop="event_type" show-overflow-tooltip align="center">
        <template slot-scope="scope" >
          <span :style="{color:enums.eventLevel[scope.row.level]=='非常紧急'?'red':enums.eventLevel[scope.row.level]=='重要事件'?'Orange':'#606266'}">{{scope.row.event_type}}</span>
        </template>
      </el-table-column>
      <el-table-column label="告警正文" prop="event_content" show-overflow-tooltip align="center">
        <template slot-scope="scope" >
          <span :style="{color:enums.eventLevel[scope.row.level]=='非常紧急'?'red':enums.eventLevel[scope.row.level]=='重要事件'?'Orange':'#606266'}">{{scope.row.event_content}}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品组" width="120" align="center" >
        <template slot-scope="scope">
            <div slot="reference" class="name-wrapper groupHeight">
              <!-- 分组 -->
              <el-tag size="medium" v-show="groupId!=scope.row.event_id"  >{{scope.row.group_names[0] }}</el-tag> 
              <el-tag size="medium" v-show="groupId==scope.row.event_id"  v-for="val in scope.row.group_names" :key="val.event_id">{{ val }}</el-tag>
              <!-- 收放 -->
              <br/>
              <button style="border: 1px solid #fff; cursor: pointer; margin-left: 3px;" v-show="scope.row.group_names.length>1&&groupId!=scope.row.event_id" @click="groupShow(scope.row.event_id)">+</button>
              <button style="border: 1px solid #fff; cursor: pointer; margin-left: 3px;" v-show="scope.row.group_names.length>1&&groupId==scope.row.event_id" @click="groupClose()">-</button>
              
            </div>
        </template>
      </el-table-column>
      <el-table-column v-if="isAndroid===-1" label="告警时间"  width="160" prop="create_time" show-overflow-tooltip align="center">
        <template slot-scope="scope" >
          <span :style="{color:enums.eventLevel[scope.row.level]=='非常紧急'?'red':enums.eventLevel[scope.row.level]=='重要事件'?'Orange':'#606266'}">{{scope.row.create_time}}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="isAndroid===-1" label="预计时间"  width="160" prop="estimated_time_to_resolve" show-overflow-tooltip align="center">
        <template slot-scope="scope" >
          <span :style="{color:enums.eventLevel[scope.row.level]=='非常紧急'?'red':enums.eventLevel[scope.row.level]=='重要事件'?'Orange':'#606266'}">{{scope.row.estimated_time_to_resolve}}</span>
        </template>
      </el-table-column>
     
      <el-table-column show-overflow-tooltip align="center" prop="state" label="事件状态" width="80">
          <template slot-scope="scope">
            <span :style="{color:enums.eventLevel[scope.row.level]=='非常紧急'?'red':enums.eventLevel[scope.row.level]=='重要事件'?'Orange':'#606266'}">{{enums.eventState[scope.row.state]}}</span>
          </template>
      </el-table-column>

      <!-- <el-table-column label="处理说明"  width="140" prop="processing_explanation" show-overflow-tooltip align="center">
      </el-table-column> -->
      <el-table-column  v-if="isAndroid===-1" label="处理人"  width="70" prop="processing_user_name" show-overflow-tooltip align="center">
        <template slot-scope="scope" >
          <span :style="{color:enums.eventLevel[scope.row.level]=='非常紧急'?'red':enums.eventLevel[scope.row.level]=='重要事件'?'Orange':'#606266'}">{{scope.row.processing_user_name}}</span>
        </template>
      </el-table-column>
      <el-table-column  v-if="isAndroid===-1" label="操作" width="120" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-show="(scope.row.write?true:false)&&scope.row.state=='alarming'"
            @click="handleEdit(scope.$index, scope.row)">领取事件</el-button>
          <el-button
            size="mini"
            v-show="(scope.row.write?true:false)&&scope.row.state=='received'"
            @click="handleDeal(scope.row)">处理事件</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pending-edit ref="pendingEdit" @getEvents="getEvents"></pending-edit>
    <index-edit ref="indexEdit" @getEvents="getEvents" ></index-edit>
   </el-main>
  </div>

</template>  
 

<script>
import indexEdit from "../../components/indexEdit"
import pendingEdit from "../../components/pendingEdit"
import enumObject from '../../utlis/enum.js';
import {getPending} from '../../api/index.js'
import { clearInterval } from 'timers';
import { constants } from 'os';
export default { 
  name: 'index',
  data () {
    return {
      productGroup:{},
      events:[],
      eventGroups:[],
      page:1,
      enums:{},
      //loading:false,
      ids:[],
      count:0,
      music:false,
      groupId:-1,
      interval:null,
      isAndroid: -1,
      tvNum:8,
    };
  },
  /* eslint-disable */
  components: { 
    "index-edit": indexEdit,
		"pending-edit": pendingEdit,
	},
  created() {
   this.isAndroid=navigator.userAgent.toLowerCase().indexOf("android")
    this.getEvents()
    this.enums=enumObject
  },
  mounted(){
  // alert(window.innerWidth)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  directives:{
  
  },
  methods: {
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while(index < array.length) {
          newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    },
    getEvents(bool){//获取事件
      let eventIds=[]
      //this.loading=true
      
      
      getPending().then(res=>{
 
        if(res.data.code==200){
          let seconds=30
          if(res.data.result.length>0){
            let ids=JSON.parse(JSON.stringify( res.data.result))
            ids.map(v=>{
              eventIds.push(v.event_id)
            })
            this.ids=eventIds
            let groups=JSON.parse(JSON.stringify( res.data.result))
            let eventGroups 
            if(this.isAndroid===-1){
             this.events=groups
            }else{
              let num=Math.floor((window.innerHeight-50)/56)
      
              eventGroups =this.group(groups, num);
              let count=0
              this.events=eventGroups[0]
              seconds=eventGroups.length*5
              this.interval=setInterval(() => {
                count++
                if(count===eventGroups.length) {
                
                  window.clearInterval(this.interval)
                }else{
                  this.events=eventGroups[count]
                }
              }, 5000);
              //this.loading=false
            }  
          }

          if(!bool){
            clearTimeout(this.eventsTimer)
            this.timer=setTimeout(() => {
              this.getEvents()
            }, seconds*1000)
          }
       
        }
      }).catch(e => {
       // this.loading=false
       
      })
    },
    handleEdit( index, row) {
    
      this.$refs.indexEdit.editReceive(index, row);
    },
    handleDeal( row) {
      this.$refs.pendingEdit.editReceive(row);
    },
    groupShow(id) {
      this.groupId=id
    },
    groupClose() {
      this.groupId=-1
    }
  },
  watch: {
    ids: {
      handler(newIds,oldIds) {
        if(newIds.length>oldIds.length||this.count>0) {
          let result= newIds.every((item)=>{
            return oldIds.indexOf(item)!=-1
          })
          if( this.count>0&&!result){   
            this.music=true
            this.$message({
              message: '您有一条新的报警信息,请加急排查哦!',
              type: 'warning'
            });
            setTimeout(() => {
              this.music=false
            }, 1200);
          }
          this.count++
        }
       deep: true
      }
    }
  } ,
} 
</script>
<style  lang="less" scoped>
  .el-tag{
    margin-top: 5px
  }
  .el-table /deep/ .el-table__body-wrapper{
    height: 83vh;
    
    overflow-y: auto;
  }
  .el-button+.el-button{
    margin-left: 0
  }
  .el-main{
    padding: 0
  }
 .el-table /deep/ td{
    padding: 0
      
  }
 .el-table /deep/ .cell{
  line-height: 21px;
      
  }
  .groupHeight{
    margin: 3px 0;
    .el-tag{
      margin:0;
    }
  }
</style> 