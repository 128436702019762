<template>
 <div >
    <el-main
      style="height: 100%;"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-form style="float: left;"  el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="任务类型">
          <el-select v-model="form.typesVal" placeholder="请选择任务类型" @change="changeTypes">
            <el-option
              v-for="item in types"
              :key="item.desc"
              :label="item.desc"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查看任务">
            <el-select v-model="form.self" placeholder="请选择需要查看的任务" @change="changeSelf">
              <el-option label="全部" value="false"></el-option>
              <el-option label="仅自己" value="true"></el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" round style="float:right" @click="createTask()">创建任务</el-button>
      <el-table
      
        :data="tableData"
        style="width: 100%">	
        <el-table-column label="任务ID"  width="140" prop="countdown_event_id" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column label="任务标题"  width="140" prop="event_title" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column label="任务类型"  width="140" prop="event_type" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column label="任务内容"  width="140" prop="event_content" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column label="产品组" width="140" align="center">
          <template slot-scope="scope">
              <div slot="reference" class="name-wrapper">
                <!-- 分组 -->
                <el-tag size="medium" v-show="groupId!=scope.row.event_id&&scope.row.product_group.length>0"  >{{scope.row.product_group[0] }}</el-tag> 
                <el-tag size="medium" v-show="groupId==scope.row.event_id"  v-for="val in scope.row.product_group" :key="val.event_id">{{ val }}</el-tag>
                <br/>
                <!-- 收放 -->
                <button style="border: 1px solid #fff; cursor: pointer; margin-left: 3px;" v-show="scope.row.product_group.length>1&&groupId!=scope.row.event_id" @click="groupShow(scope.row.event_id)">+</button>
                <button style="border: 1px solid #fff; cursor: pointer; margin-left: 3px;" v-show="scope.row.product_group.length>1&&groupId==scope.row.event_id" @click="groupClose()">-</button>
         
              </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间"  width="160" prop="create_time" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column label="倒计时时间"  width="160" prop="crash_time" show-overflow-tooltip align="center">
        </el-table-column>
      
        <el-table-column label="创建者"  width="140" prop="user" show-overflow-tooltip align="center">
        </el-table-column>
      
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.row.countdown_event_id)">关闭任务</el-button>
          </template>
        </el-table-column>
      </el-table>
    <div class="block" style="float: right"> 
      <i v-show="page!=1" @click="prev">上一页</i>
      <i v-show="nextPage" @click="next">下一页</i>
    </div>
    <timer-edit ref="timerEdit" :types="types" @getCountdown="getCountdown"></timer-edit>
    </el-main>
  </div>

</template>  
 
<script>
import timerEdit from "./timerEdit"
import {getTypes,getCountdown,postTimingTask,createTask} from '../../api/index.js'
export default { 
  name: 'timer',
  data () {
    return {
      types:[],
      form:{
        typesVal:'all',
        self:'false',
      },
      loading:false,
      page:1,
      nextPage:false,
      tableData:[],
      groupId:-1,
    };
  },
  components: { 
		"timer-edit": timerEdit,
	},
  created() {
    this.getType()
    this.getCountdown()
  },
 
  methods: {
    changeTypes(){
      this.getCountdown()
    },
    changeSelf(){
      this.getCountdown()
    },
    getType(){//获取任务类型
      this.loading=true
      getTypes().then(res=>{
        if(res.data.code==200){
          this.types=res.data.result
          this.types.unshift({ 
            'desc': '全部',
            'key': 'all'
          })
          
        }
      })
     
    },
    getCountdown(){//获取所有事件
      let limit=11
      this.loading=true
      getCountdown(this.page,limit,this.form.self,this.form.typesVal).then(res=>{
        if(res.data.code==200){
          
          this.loading=false
          this.tableData=res.data.result
          this.nextPage=res.data.next_page
        }
      }).catch(e => {
        this.loading=false
       
      })
    },
    prev() {
      if(this.page>1) {
        this.page-=1
        this.getCountdown()
      }
    },
    next() {
      if(this.nextPage){
        this.page+=1
        this.getCountdown()
      }
    },
    handleEdit(id) {//修改任务
      this.loading=true
      postTimingTask(id,false).then(res=>{
        if(res.data.code==200){
          this.$message({
            message: '关闭倒计时任务成功',
            type: 'success'
          });
          this.getCountdown()
        }
      }).catch(e => {
       
        this.loading=false
      })
    },
    createTask(){//创建任务
      this.$refs.timerEdit.editReceive();
    },
    groupShow(id) {
      this.groupId=id
    },
    groupClose() {
      this.groupId=-1
    }
  }

} 
</script>
<style  lang="less" scoped>
  .el-form-item{
    display: inline-block;
    
  }
  .el-table {

  }
  .el-table /deep/ .el-table__body-wrapper{
   height:72vh;
    overflow-y: auto;
  }
  .block{
    margin-top: 22px;
  }
  .block i{
    margin-right: 22px;
    cursor: pointer;
    font-weight: 700;
   
  }
</style> 