<template>
	<div>
		<!-- 处理状态 -->
    <el-dialog
      class="dialog"
      title="处理报警事件"
      :visible.sync="receiveDialog"
      width="30%"
      center
      style="text-align: center;"
      :before-close="handleClose"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="处理结果">
          <el-select v-model="form.result" placeholder="请对事件进行标记">
            <el-option label="已处理" value="processed"></el-option>
            <el-option label="已忽略" value="ignored"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理说明" label-width="80px">
          <el-input type="textarea"  v-model="form.text"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="receiveSure">确 定</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
import {postHandle} from '../api/index.js'
export default {
    name: "pendingEdit",
    data() {
      return{
        receiveDialog: false,
        estimatedTime: "",
        receiveRow:{},
         form: {
          text: '',
          result: '',
        },
        loading:false,
      }
    },
    created() {},
    mounted() {
      
    },
    methods: {
      editReceive(row) {
        let that = this
        that.receiveDialog = true
        that.receiveRow = row
        that.loading=false
      }, 
      receiveSure() { 
        let that = this
        this.loading=true
        postHandle(that.receiveRow.event_id,that.form.text,that.form.result).then(res=>{
          if(res.data.code==200){
            that.form= {
              text: '',
              result: '',
             }
             that.receiveDialog = false
             that.$emit('getEvents',true)
          }
        }).catch(error => {
          that.loading=false
        
				});
      }, 
      handleClose() { 
        this.form= {
          text: '',
          result: '',
        }
        this.receiveDialog = false
       
      }
    }
  }
</script>

<style lang="less" scoped>
.el-select{ 
  width: 100%
}

</style>