import "core-js/modules/es.array.index-of.js";
//
//
//
//
//
//
//
//
//
//
//
import Aside from './Aside';
import Header from './Header';
export default {
  data: function data() {
    return {
      isAndroid: -1
    };
  },
  created: function created() {
    this.isAndroid = navigator.userAgent.toLowerCase().indexOf("android");
  },
  components: {
    Aside: Aside,
    Header: Header
  },
  methods: {}
};