import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import axios from 'axios'
import moment from "moment";
Vue.use(ElementUI);
Vue.config.productionTip = false 
Vue.prototype.$moment = moment;

// 响应拦截

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(22,error)
    if (error.response.status === 500) {
      ElementUI.Message.error('网络异常')
    } else if (error.response.status === 406) {
      ElementUI.Message.error('账号过期,即将前往登录')
      router.push({
        path:'/login',
      })
    } else {
      ElementUI.Message.error(error.response.data.error)
    }
    return Promise.reject(error);
});



new Vue({ 
  router,
  render: h => h(App),
}).$mount('#app')
 