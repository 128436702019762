import Vue from 'vue'
import Router from 'vue-router'
import all from './views/all/all.vue'
import login from './views/login.vue'
import index from './views/main/index.vue'
import code from './views/code/code.vue'
import authorize from './views/authorize/authorize.vue'
import timer from './views/timer/timer.vue'
import Frame from './components/Frame.vue';

Vue.use(Router)



var router = new Router({
//	base: '/webs/indicia',
	base: '/', 
	//mode: 'history', 
	routes: [{   
			path: '/',
      redirect: '/index',  
 
		},  
		{
			path: '/login', 
			name: 'login',
			component: login
		},
		{ 
			path: '/index', 
			component:Frame ,
			children: [{
				path: '',
				name: 'index',
				component: index
			}]
		},
		{ 
			path: '/all', 
			component:Frame ,
			children: [{
				path: '',
				name: 'all',
				component: all
			}]
		},
		
		{ 
			path: '/code', 
			component:Frame ,
			children: [{
				path: '',
				name: 'code',
				component: code
			}]
		},
		{ 
			path: '/authorize', 
			component:Frame ,
			children: [{
				path: '',
				name: 'authorize',
				component: authorize
			}]
		},
		{ 
			path: '/timer', 
			component:Frame ,
			children: [{
				path: '',
				name: 'timer',
				component: timer
			}]
		},
	]
})
/*  router.beforeEach((to, from, next) => {
	if (to.path === '/login') {
		next();
	} else {
		let token = sessionStorage.getItem("login")
		console.log(token);
		if (token === null || token === '') {
			
			next('/login');
			//console.log('非登录入口' + token);
		} else {
			// next('/login');
			//console.log('登录入口' + token);
			next();
		}
	}
 }) */
 export default router;