<template>
	<div 
    class="authorize" 
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    >
    <el-button type="primary" round style="position: fixed;right: 37px; top:100px;" @click="editSure">确定修改</el-button>
    <div class="authorize-read">
      <h2>查看权限</h2>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="readArr" >
        <el-checkbox style="display :block" v-for="item in groups" :label="item.group_id" :key="item.group_id">
          {{item.group_name}}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="authorize-write">
      <h2>操作权限</h2>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="writeArr" >
        <el-checkbox style="display :block" v-for="item in groups" :label="item.group_id" :key="item.group_id">
          {{item.group_name}}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    
	</div>
</template>

<script>
import {getGroup,postAuthorize} from '../../api/index.js'
export default { 
  name: 'authorize',
  data () {
    return {
      readArr: [],
      writeArr: [],
      groups: [],
      isIndeterminate: true,
      loading:false,
    };
  },
  created() {
    this.getproductGroup()
  },
  methods: {
    getproductGroup(){
      this.loading=true
      this.readArr=[]
      this.writeArr=[]
      getGroup().then(res=>{
        if(res.status==200){
          this.groups=res.data.result
          this.groups.filter((item)=>{
            if(item.read==true){
              this.readArr.push(item.group_id)
            }
            if(item.write==true){
              this.writeArr.push(item.group_id)
            }
          })
          this.loading=false
        }
      }).catch(e => {
        this.loading=false
       
      })
    },
    editSure() {
      this.loading=true
      postAuthorize(this.readArr,this.writeArr).then(res=>{
        console.log(res)
        if(res.data.code==200){
          this.$message.success('修改成功');
          this.getproductGroup()
          
        }
      }).catch(e => {
        this.loading=false
        
      })
      console.log( this.readArr)
    },
  }

} 
</script>

<style lang="less" scoped>
  .el-checkbox{
    margin-bottom: 20px;
    
  }
  .authorize-read{
    position: fixed;
    left: 35%;
    top: 15%;
  }
  .authorize-write{
    position: fixed;
    left: 65%;
    top: 15%;
  }
</style>