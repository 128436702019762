<template>
	<div>
		<!-- 处理状态 -->
    <el-dialog
      class="dialog"
      title="领取报警事件"
      :visible.sync="receiveDialog"
      width="30%"
      center
      style="text-align: center;"
      :before-close="handleClose"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      >
      <span class="demonstration">预计的解决时间</span>
      <br/>
      <el-date-picker
        v-model="estimatedTime"
        type="datetime"
        placeholder="选择日期时间"
        style=" margin-top: 50px;"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="receiveSure">确 定</el-button>
      </span>
    </el-dialog>
	</div>
</template>

<script>
import {postReceive} from '../api/index.js'
export default {
    name: "indexEdit",
    data() {
      return{
        receiveDialog: false,
        estimatedTime: "",
        receiveRow:{},
        loading:false,
      }
    },
    created() {},
    mounted() {
      
    },
    methods: {
      editReceive($index, row) {
        let that = this
        console.log($index, row)
        that.receiveDialog = true
        that.receiveRow = row
        that.loading=false
      }, 
      receiveSure() { 
        let that = this
        let Time=this.$moment(that.estimatedTime).format("YYYY-MM-DD HH:mm:ss")
        this.loading=true
       
        postReceive(that.receiveRow.event_id,Time).then(res=>{
          if(res.data.code==200){
             that.$emit('getEvents');
             that.estimatedTime=""
             that.receiveDialog = false
          }
        }).catch(error => {
          this.loading=false
         
				});
      }, 
      handleClose() { 
     
        this.estimatedTime=""
        this.receiveDialog = false
      }
    }
  }
</script>

<style lang="less" scoped>
  .dialog /deep/ .el-dialog__body{
    text-align: center!important;
  }
</style>