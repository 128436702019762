<template>
	<el-container style="width: 400px; position: fixed;top: 40%;left: 48.7%;transform: translate(-50%,-50%);">
		<h2 v-if="isAndroid!=-1" style="position: fixed;top: 20%;left: 45%;transform: translate(-50%,-50%);">授权码:{{code}}</h2>
		<el-form v-else ref="form" class="login-form" :rules="rules" :model="form">
			<el-form-item label="邮箱" prop="email">
				<el-input v-model="form.email"></el-input>
				<p style="position: absolute;top: 30%;right: -50px;cursor: pointer; font-weight: 550; font-size: 16px;" v-if="isRegister" @click="sendEmail()" :class="emailMsg=='发送'?'':'emailColor'">{{emailMsg}}</p>
			</el-form-item>
			<el-form-item v-if="isRegister" label="验证码" prop="code">
				<el-input v-model="form.code"></el-input>
			</el-form-item>
			<el-form-item v-if="isRegister" label="名字" prop="name">
				<el-input v-model="form.name"></el-input>
			</el-form-item>
			<el-form-item label="密码" prop="password">
				<el-input v-model="form.password" type="password" @keyup.enter.native="handleLogin"></el-input>
			</el-form-item>
			<el-form-item v-if="isRegister" style="text-align: center;">
				<el-button @click="ensure" type="primary" >确定</el-button>
				<el-button @click="cancel"  type="success" >取消</el-button>
			</el-form-item>
			<el-form-item   v-else  style="text-align: center;">
				<el-button @click="handleLogin" type="primary" >登陆</el-button>
				<el-button @click="handleRegister"  type="success" >注册</el-button>
			</el-form-item>
		</el-form>
	</el-container>
</template>
nm
<script>
	import {
		getCode,getToken,login,register,getAuthcode,postEmail
	} from '../api/index.js'
import { setTimeout, clearTimeout } from 'timers';

	export default {
		data() {
			return {
				isAndroid: -1,
				code: '请稍后',
				codeToken: '',
				form: {
					email: '',
					password: '',
					name: '',
					code: '',
				},
				logining: false,
				register: false,
				isRegister: false,
				rules: {
					email: [{
						required: true,
						message: '请输入邮箱'
					}],
					password: [{
						required: true,
						message: '请输入密码'
					}],
					code: [{
						required: true,
						message: '请输入验证码'
					}],
					name: [{
						required: true,
						message: '请输入名字'
					}],

				},
				timer:null,
				emailMsg:'发送',
			}
		},
		created(){
			this.isAndroid=navigator.userAgent.toLowerCase().indexOf("android")
			if(this.isAndroid!=-1){
				getCode().then(res=>{//获取code码
					if (res.data.code === 200) {
						this.code=res.data.authorize_code
						this.codeToken=res.data.token
						this.getState(this.codeToken)
					} 
				})
			} 
		},
		methods: {
			getState(token){//授权码登录
				let that=this
				getToken(token).then(res=>{
					if(res.data.code==200){
						clearTimeout(this.timer)
						//sessionStorage.setItem('login','ok')
						localStorage.setItem("username",'授权码用户')
						that.$router.push('/index')
					}else if(res.data.code==201){
						/* eslint-disable */
					 this.timer=setTimeout(() => {
							 that.getState(token)
							 clearTimeout(this.timer)
						}, 1500)
					}
				})
			},
			handleLogin() {//正常登录
				this.$refs['form'].validate((valid) => {
					if (valid) {
						login(this.form.email, this.form.password).then(res => {
								console.log(222,res)
								if (res.data.code === 200) {
								 // sessionStorage.setItem('login','ok')
								 	localStorage.setItem("username",this.form.email)
									this.$router.push('/index')
									clearTimeout(this.timer)
								} 
							})
					} else {
						
						return false
					}
				})
			},
			handleRegister(){
				this.isRegister=true
				this.$refs['form'].resetFields();
			},
			sendEmail() {//邮箱验证
				postEmail(this.form.email).then(res=>{//获取code码
					if (res.data.code === 200) {
							let count=59
							let timer=setInterval(() => {
								if(count<0){
									this.emailMsg='发送'
									clearInterval(timer)
								}else{
									this.emailMsg=count
									count-=1
								}
							}, 1000);
					} 
				})
			},
			ensure(){//注册确认
				this.$refs['form'].validate((valid) => {
					if (valid) {
						let boolean = new RegExp("^([a-zA-Z0-9])*[a-zA-Z0-9]+@venpoo.com|@leqi.us|@leqigroup.com$").test(this.form.email)
						if (!boolean) {
							this.$message.warning("请输入以venpoo.com或leqi.us结尾的的邮箱");
						}else{	
							this.register = true
							register(this.form.email, this.form.name, this.form.password,this.form.code)
								.then(res => {
									let a=res.headers
									console.log(res)
									if (res.data.code === 200) {
										localStorage.setItem("username",this.form.email)
										this.$router.push('/index')
									} else {
										this.$message.error('账号或者密码错误')
									}
								})
								.then(() => {
									this.register = false
								})
						}
					} else {
						return false
					} 
				})
			},
			cancel(){
				this.isRegister=false
				this.$refs['form'].resetFields();
			}
		},
		
		beforeDestroy(){
			clearTimeout(this.timer)
			
		}
	}
</script>

<style scoped="scoped" lang="less">

  .el-input /deep/ input{
		width: 350px;
	
	}
	.page {
		width: 100%;
		height: 100%;
	}

	.login-form {
		padding-top: 120px;
	}
	.emailColor {
		color: #2f2fd6
	}
</style>