//
//
//
//
//
//
//
//

/* import Aside from './components/Aside' */
export default {
  data: function data() {
    return {};
  },
  created: function created() {},
  components: {//	Aside,
  }
};