<template>
	<el-aside v-if="isAndroid===-1" width="232px" style="background: #07080B;text-align: left;overflow: hidden;height: 100%;">
		<div class='aside-title'>
			
			乐骐科技报警预警
		</div>
		<el-menu router background-color="#07080B" active-text-color="#FFFFFF" text-color="#BEC0C2" style="width: 232px;">
			<el-menu-item index=1 route="/index" >
				<i class="el-icon-s-data"></i>
				<span slot="title">正在报警</span>
			</el-menu-item>
			
			<el-menu-item index=2 route="/all" >
				<i class="el-icon-s-marketing"></i>
				<span slot="title">所有事件</span>
			</el-menu-item>

			<el-menu-item index=3 route="/code">
					<i class="el-icon-s-platform"></i>
					<span slot="title">授权码</span>
			</el-menu-item>

			<el-menu-item index=4 route="/authorize">
					<i class="el-icon-user-solid"></i>
					<span slot="title">权限管理</span>
			</el-menu-item>

			<el-menu-item index=5 route="/timer">
					<i class="el-icon-time"></i>
					<span slot="title">计时事件</span>
			</el-menu-item>

		</el-menu>
	</el-aside>

</template>

<script>

	export default {
		data(){
			return{
				isAndroid: -1,
			}
		},
		created() {
			this.isAndroid=navigator.userAgent.toLowerCase().indexOf("android")
		}
	}
</script>

<style>
.el-menu-item.is-active{
	background: #24325E !important;
}

.el-menu-item, .el-submenu__title{
	height: 97px;
	line-height: 97px;
}
.aside-title{
	height: 70px;
	font-size: 20px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
}
.aside-title img{
	width: 22px;
	height: 25px;
	margin-right: 4px;
}
</style>
